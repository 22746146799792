import React from "react";
import clsx from "clsx";
import { Container, Text, Button } from "@atoms";
import ButtonListPattern from "@svg/ButtonListPattern";

const ButtonList = ({
  heading,
  descriptor,
  links,
  color,
  className: _className,
}) => {
  return (
    <section
      className={clsx(
        "mx-auto my-10 max-w-7xl px-3 md:my-20",
        _className
      )}
    >
      <div
        className={clsx(
          "relative overflow-hidden rounded-b-3xl rounded-tl-3xl rounded-tr-3xl px-3 py-8 md:rounded-tl-6xl md:p-16",
          {
            "bg-teal": color === "teal",
            "bg-purple": color === "purple",
            "bg-pink": color === "pink",
            "bg-yellow": color === "yellow",
          }
        )}
      >
        <div className="absolute -bottom-14 -right-2 h-auto w-[32rem] xxs:-right-8 xxs:w-[24rem]">
          <ButtonListPattern
            className={clsx("h-full w-full", {
              "text-teal": color === "purple",
              "text-white": color !== "purple",
            })}
          />
        </div>
        <Container variant="xs" className="relative z-10">
          <div className="flex flex-col items-center space-y-4">
            <div className="-mx-3 flex flex-col space-y-8 text-center text-white sm:-mx-0">
              <Text variant="h3">{heading}</Text>
              <Text variant="body">{descriptor}</Text>
            </div>
            <div className="flex max-w-sm flex-wrap justify-center flex-gap-8">
              {!!links?.length &&
                links.map(link => {
                  return (
                    <div>
                      <Button
                        uid={link.uid}
                        to={link.url}
                        size="sm"
                        color={color === "yellow" ? "purple" : "white"}
                      >
                        {link.text}
                      </Button>
                    </div>
                  );
                })}
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

ButtonList.defaultProps = {
  color: "purple",
};

export default ButtonList;
